* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  margin: 0;
  color: white;
  background-color: black;
  font-family: "DM Sans", sans-serif;
  z-index: 200;
}

:root {
  --background-color-1: #0f0f0f;
  --background-color-2: #1b1b1c;
  --background-color-3: #161516;
  --background-color-4: #0b0a0a;
  --gradient-color-1: #6ec3f4;
  --gradient-color-2: #3a3aff;
  --gradient-color-3: #ff61ab;
  --gradient-color-4: #e63946;
}

.image-container {
  height: 500;
  width: 500;
  background-size: cover;
  background-position: center;
  -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.meta-container {
  /* clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%); */
  clip: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.underlined {
  /*   background: red; */
  position: relative;
}

.underline-mask:after {
  content: "";
  position: absolute;
  top: 95%;
  width: 150%;
  aspect-ratio: 3 / 1;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 50%;
  border: 6px solid hsl(280 80% 50%);
  /* Use a conic gradient mask to hide and show the bits you want */
  --spread: 140deg;
  --start: 290deg;
  mask: conic-gradient(
    from var(--start),
    white 0 var(--spread),
    transparent var(--spread)
  );
}

.underline-overflow {
  display: inline-block;
  overflow: hidden;
  padding-bottom: clamp(1rem, 2vmin, 2rem);
}
.underline-overflow:after {
  content: "";
  position: absolute;
  top: 100%;
  height: 150%;
  aspect-ratio: 2.5 / 1;
  left: 50%;
  transform: translate(-50%, -10%);
  border-radius: 50%;
  border: 6px solid hsl(10 80% 50%);
}

.underline-clip:after {
  content: "";
  position: absolute;
  top: 95%;
  width: 150%;
  aspect-ratio: 3 / 1;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 50%;
  border: 6px solid hsl(130 80% 50%);
  clip-path: polygon(0 0, 50% 50%, 100% 0);
}

.get-inside {
  padding: 0.5rem 2rem;
  border-radius: 4px;
  font-size: large;
  font-family: "DM Sans", sans-serif;
  background: #0b0a0a;
  color: white;
  border: 0;
  cursor: pointer;
  box-shadow: 0 0 0 0.5px #161516;
  transition: all 0.6s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slash {
  width: 0;
  transition: all 0.6s ease-in-out;
}

.get-inside:hover {
  background: #161516;
  box-shadow: 0 0 0 0.5px #0b0a0a;
}

.get-inside:hover > .slash {
  width: 15px;
}

.button-icon {
  animation: button-icon 1s infinite;
}

@keyframes button-icon {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.5rem);
  }
  100% {
    transform: translateX(0);
  }
}

.experience__entry {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 2em;
  transition: all 1.6s ease-in-out;
  overflow: hidden;
  margin-top: 20px;
}

.experience__entry__meta {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.experience__entry__data {
  display: flex;
  align-items: center;
  gap: 20px;
}

.experience__date {
  font-size: 12px;
  color: hsl(0, 0%, 56%);
}

.experience__details {
  height: 0;
  transition: all 1s ease-in-out;
}

.experience__entry:hover {
  background-color: #1f1f1f;
}

.experience__entry:hover > .experience__entry__meta {
  transform: scale(1.05);
}

.experience__entry:hover > .experience__details {
  height: 120px;
}

.experience__link {
  color: #777676;
  padding: 0.4em 1em;
  border-radius: 3em;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
}

.experience__link:hover {
  background-color: #0b0a0a;
  color: white;
}

.experience__title {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.mantine-Timeline-item {
  margin-inline-start: 2em;
  width: 100%;
}

.quick__link {
  background-color: #2f2f2f5a;
  padding: 1em;
  cursor: pointer;
}

.quick__link:not(:first-child) {
  margin-top: 1em;
}

.quick__link__title {
  font-size: 1.2em;
  font-weight: 600;
  color: #777676;
  font-family: "Plus Jakarta Sans", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

small {
  font-family: "DM Sans", sans-serif;
}
